<body id="home">
  <header>
    <section id="header_blk" class="logo">
      <div class="container">
        <div class="row">
          <div class="col-md-10 col-sm-10 col-xs-10 text-left">
            <a href=""
              ><img
                src="../../../assets/img/logo.png"
                alt="Logo"
                style="width: auto"
            /></a>
          </div>
          <!-- nav -->
          <app-nav></app-nav>
          <!-- nav -->
        </div>
      </div>
    </section>
  </header>

  <section id="slider_blk_img">
    <p><img src="../../../assets/img/clients.jpg" /></p>
  </section>

  <section id="clients">
    <div class="container">
      <div class="row">
        <div class="col-md-12 nopadding">
          <h1 style="text-align: center; margin-bottom: 15px">Our Clients</h1>
          <div class="client-slider">
            <ul
              class="gallery js-flickity"
              data-flickity-options='{ "freeScroll": true, "wrapAround": true, "autoPlay": true, "autoPlay": 1000 }'
            >
              <li class="gallery-cell">
                <a href="clients"
                  ><img src="../../../assets/img/client/ab-innovative.jpg"
                /></a>
              </li>
              <li>
                <a href="clients"
                  ><img src="../../../assets/img/client/accenture.jpg"
                /></a>
              </li>
              <li>
                <a href="clients"
                  ><img src="../../../assets/img/client/all-script.jpg"
                /></a>
              </li>
              <li>
                <a href="clients"
                  ><img src="../../../assets/img/client/all-state.jpg"
                /></a>
              </li>
              <li>
                <a href="clients"
                  ><img src="../../../assets/img/client/amadeus.jpg"
                /></a>
              </li>
              <li>
                <a href="clients"
                  ><img src="../../../assets/img/client/antares.jpg"
                /></a>
              </li>
              <li>
                <a href="clients"
                  ><img src="../../../assets/img/client/areva.jpg"
                /></a>
              </li>
              <li>
                <a href="clients"
                  ><img src="../../../assets/img/client/cerner.jpg"
                /></a>
              </li>
              <li>
                <a href="clients"
                  ><img src="../../../assets/img/client/compassites.jpg"
                /></a>
              </li>
              <li>
                <a href="clients"
                  ><img src="../../../assets/img/client/endeavour.jpg"
                /></a>
              </li>
              <li>
                <a href="clients"
                  ><img src="../../../assets/img/client/fiserv.jpg"
                /></a>
              </li>
              <li>
                <a href="clients"
                  ><img src="../../../assets/img/client/flexera.jpg	 "
                /></a>
              </li>
              <li>
                <a href="clients"
                  ><img src="../../../assets/img/client/ge.jpg"
                /></a>
              </li>
              <li>
                <a href="clients"
                  ><img src="../../../assets/img/client/hcl.jpg"
                /></a>
              </li>
              <li>
                <a href="clients"
                  ><img src="../../../assets/img/client/infinite.jpg"
                /></a>
              </li>
              <li>
                <a href="clients"
                  ><img src="../../../assets/img/client/keystone-logic.jpg"
                /></a>
              </li>
              <li>
                <a href="clients"
                  ><img src="../../../assets/img/client/manhattan.jpg"
                /></a>
              </li>
              <li>
                <a href="clients"
                  ><img src="../../../assets/img/client/mindtree.jpg"
                /></a>
              </li>
              <li>
                <a href="clients"
                  ><img src="../../../assets/img/client/nds.jpg"
                /></a>
              </li>
              <li>
                <a href="clients"
                  ><img src="../../../assets/img/client/ness.jpg"
                /></a>
              </li>
              <li>
                <a href="clients"
                  ><img src="../../../assets/img/client/novell.jpg"
                /></a>
              </li>
              <li>
                <a href="clients"
                  ><img src="../../../assets/img/client/opteamix.jpg"
                /></a>
              </li>
              <li>
                <a href="clients"
                  ><img src="../../../assets/img/client/oracle.jpg"
                /></a>
              </li>
              <li>
                <a href="clients"
                  ><img src="../../../assets/img/client/philips.jpg"
                /></a>
              </li>
              <li>
                <a href="clients"
                  ><img src="../../../assets/img/client/quintiles.jpg"
                /></a>
              </li>
              <li>
                <a href="clients"
                  ><img src="../../../assets/img/client/siemens.jpg"
                /></a>
              </li>
              <li>
                <a href="clients"
                  ><img src="../../../assets/img/client/sinda.jpg"
                /></a>
              </li>
              <li>
                <a href="clients"
                  ><img src="../../../assets/img/client/slk.jpg"
                /></a>
              </li>
              <li>
                <a href="clients"
                  ><img src="../../../assets/img/client/spider.jpg"
                /></a>
              </li>
              <li>
                <a href="clients"
                  ><img src="../../../assets/img/client/tcs.jpg"
                /></a>
              </li>
              <li>
                <a href="clients"
                  ><img src="../../../assets/img/client/wipfli.jpg"
                /></a>
              </li>
              <li>
                <a href="clients"
                  ><img src="../../../assets/img/client/wipro.jpg"
                /></a>
              </li>
              <li>
                <a href="clients"
                  ><img src="../../../assets/img/client/xerox.jpg"
                /></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- footer -->
  <app-footer></app-footer>
  <!-- footer -->

  <div id="toTop">
    <a href="clients#home"><img src="../../../assets/img/gotop.png" /></a>
  </div>
</body>
