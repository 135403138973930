<body id="home">
  <header>
    <section id="header_blk" class="logo">
      <div class="container">
        <div class="row">
          <div class="col-md-10 col-sm-10 col-xs-10 text-left">
            <a href=""
              ><img
                src="../../../assets/img/logo.png"
                alt="Logo"
                style="width: auto"
            /></a>
          </div>
          <!-- nav -->
          <app-nav></app-nav>
          <!-- nav -->
        </div>
      </div>
    </section>
  </header>

  <section id="slider_blk">
    <ul class="bxslider">
      <li><img src="../../../assets/img/student1.jpg" /></li>
      <li><img src="../../../assets/img/student6.jpg" /></li>
      <li><img src="../../../assets/img/student3.jpg" /></li>
      <li><img src="../../../assets/img/student4.jpg" /></li>
      <li><img src="../../../assets/img/student5.jpg" /></li>
      <li><img src="../../../assets/img/student2.jpg" /></li>
    </ul>
  </section>

  <section id="hme2" style="padding-bottom: 0px">
    <div class="cust_wt">
      <div class="container">
        <div class="row">
          <div class="col-md-12 text-center">
            <h1>Students Speak</h1>
          </div>
        </div>
        <div class="row">
          <div class="col-md-2">
            <p>
              <img
                src="../../../assets/img/participants1.jpg"
                style="max-width: 163px"
              />
            </p>
          </div>
          <div class="col-md-10">
            <p>
              I was lucky to get selected for the training program(RTD6) held by
              PRATIAN .The way they selected us was most important part of our
              training. First Of all I would like to thank the trainers those
              who trained us to make worthy enough to be selected In such a good
              company like Quintiles such as Jankiram Sir, Venkat Sir, Krishna
              and of-course Nidhi and Subramaniam Sir and All those who are part
              of the PRATIAN family with out whom this can not be possible. The
              level of training provided to us was awesome and the way they
              guide us was just fantastic. At last I can just say that you
              people are doing a great and novel job so please carry on this
              training program for other students also so that they can also be
              part of such a wonderful training experience. Thank you for giving
              the opportunity to take training under such wonderful persons.
            </p>
            <p><strong>Anil Mishra</strong></p>
          </div>
        </div>
      </div>
    </div>

    <div class="cust_grey">
      <div class="container">
        <div class="row">
          <div class="col-md-10">
            <p>
              As far as my opinion is concerned, this is the best place to learn
              doing everything in a productive way. I have been working on
              different languages from long time and I was capable of doing most
              of the things but it wasn't necessary that I use different levels
              of abstraction and all. but after I joined SkillAssure I got an
              idea of how to put up logic and exactly where it is required.
              SkillAssure is the biggest place for learning things that would
              help you while you are working in corporate world. Thanks to all
              for providing such a wonderful experience in learning. It is good
              to be a part of SkillAssure Family that made me enter into a
              fabulous working environment -"Quintiles Technologies (I) Pvt.
              Ltd."
            </p>
            <p><strong>Rituja Srivastav</strong></p>
          </div>
          <div class="col-md-2">
            <p>
              <img
                src="../../../assets/img/participants2.jpg"
                style="max-width: 163px"
              />
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="cust_wt">
      <div class="container">
        <div class="row">
          <div class="col-md-2">
            <p>
              <img
                src="../../../assets/img/participants3.jpg"
                style="max-width: 163px"
              />
            </p>
          </div>
          <div class="col-md-10">
            <p>
              They say that everything happens for a reason. Well I couldn't
              agree more! I completed my B.E. degree in the field of Computer
              Science this year and wasn't too sure about where to head next.
              Amid all this confusion I came across SKILLASSURE model of
              learning which felt so right and honest that I put away all my
              apprehensions and just went ahead with it. It was the 10th of June
              when we started with the training and the next 2 months were like
              a roller coaster ride, so full of learning. The whole experience
              has helped me become more confident as an individual and also
              capable enough to bag a job at Keystone Logic!
            </p>
            <p><strong>Harneet Nanda</strong></p>
          </div>
        </div>
      </div>
    </div>

    <div class="cust_grey">
      <div class="container">
        <div class="row">
          <div class="col-md-10">
            <p>
              "Pratian Technologies" tremendously helped me to learn and
              experience the model of "Learning by doing". Students are
              encouraged and the theoretical knowledge along with practical
              training the students received here is praise worthy. Besides
              these, the institute also conducts meeting with various
              personalities having good name and work experience in software
              field. These visit have helped to simplify studies through the
              method of "Learning by seeing". The ever ready co-operation and
              support we receive from the faculty are highly appreciated and
              they are always ready to help the students when they have any
              problem or queries. I am very much inspired by all the Teaching
              faculty and Management. Pratian Technologies uphold discipline ,
              hardwork , Character Formation and value oriented training. It was
              also the time to explore our own. At last-bring one step towards
              them, they will bring two steps towards you.
            </p>
            <p><strong>Aqeel Adbulla</strong></p>
          </div>
          <div class="col-md-2">
            <p>
              <img
                src="../../../assets/img/participants4.jpg"
                style="max-width: 163px"
              />
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="screenshots" id="gallery">
    <div class="container-fluid">
      <h1>Our Gallery</h1>
      <div class="row">
        <ul class="grid">
          <li>
          
              <img src="../../../assets/img/01-screenshot.jpg" alt="01" />
              <figcaption>
                <div class="caption-content">
                  <a
                    href="../../../assets/img/large/01.jpg"
                    class="single_image"
                  >
                    <i class="fa fa-search"></i>
                  </a>
                </div>
              </figcaption>
      
          </li>
          <li>
          
              <img src="../../../assets/img/02-screenshot.jpg" alt="01" />
              <figcaption>
                <div class="caption-content">
                  <a
                    href="../../../assets/img/large/02.jpg"
                    class="single_image"
                  >
                    <i class="fa fa-search"></i>
                  </a>
                </div>
              </figcaption>
      
          </li>
          <li>
          
              <img src="../../../assets/img/03-screenshot.jpg" alt="01" />
              <figcaption>
                <div class="caption-content">
                  <a
                    href="../../../assets/img/large/03.jpg"
                    class="single_image"
                  >
                    <i class="fa fa-search"></i>
                  </a>
                </div>
              </figcaption>
      
          </li>
          <li>
          
              <img src="../../../assets/img/04-screenshot.jpg" alt="01" />
              <figcaption>
                <div class="caption-content">
                  <a
                    href="../../../assets/img/large/04.jpg"
                    class="single_image"
                  >
                    <i class="fa fa-search"></i>
                  </a>
                </div>
              </figcaption>
      
          </li>
          <li>
          
              <img src="../../../assets/img/05-screenshot.jpg" alt="01" />
              <figcaption>
                <div class="caption-content">
                  <a
                    href="../../../assets/img/large/05.jpg"
                    class="single_image"
                  >
                    <i class="fa fa-search"></i>
                  </a>
                </div>
              </figcaption>
      
          </li>
          <li>
          
              <img src="../../../assets/img/06-screenshot.jpg" alt="01" />
              <figcaption>
                <div class="caption-content">
                  <a
                    href="../../../assets/img/large/06.jpg"
                    class="single_image"
                  >
                    <i class="fa fa-search"></i>
                  </a>
                </div>
              </figcaption>
      
          </li>
          <li>
          
              <img src="../../../assets/img/10-screenshot.jpg" alt="01" />
              <figcaption>
                <div class="caption-content">
                  <a
                    href="../../../assets/img/large/10.jpg"
                    class="single_image"
                  >
                    <i class="fa fa-search"></i>
                  </a>
                </div>
              </figcaption>
      
          </li>
          <li>
          
              <img src="../../../assets/img/11-screenshot.jpg" alt="01" />
              <figcaption>
                <div class="caption-content">
                  <a
                    href="../../../assets/img/large/11.jpg"
                    class="single_image"
                  >
                    <i class="fa fa-search"></i>
                  </a>
                </div>
              </figcaption>
      
          </li>
        </ul>
      </div>
    </div>
  </section>

  <!-- footer -->
  <app-footer></app-footer>
  <!-- footer -->

  <div id="toTop">
    <a href="student-speak#home"><img src="../../../assets/img/gotop.png" /></a>
  </div>
</body>
