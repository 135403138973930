<body id="home">
  <header>
    <section id="header_blk" class="logo">
      <div class="container">
        <div class="row">
          <div class="col-md-10 col-sm-10 col-xs-10 text-left">
            <a href=""
              ><img src="../../../assets/img/logo.png" alt="Global Edify"
            /></a>
          </div>
            <!-- nav -->
            <app-nav></app-nav>
            <!-- nav -->
        </div>
      </div>
    </section>
  </header>

  <section id="slider_blk">
    <ul class="bxslider">
      <li><img src="../../../assets/img/slide1.jpg" /></li>
      <li><img src="../../../assets/img/slide2.jpg" /></li>
      <li><img src="../../../assets/img/slide3.jpg" /></li>
      <li><img src="../../../assets/img/slide4.jpg" /></li>
      <li><img src="../../../assets/img/slide5.jpg" /></li>
      <li><img src="../../../assets/img/slide6.jpg" /></li>
      <li><img src="../../../assets/img/slide7.jpg" /></li>
      <li><img src="../../../assets/img/slide8.jpg" /></li>
    </ul>
  </section>

  <div id="all_cont_blk">
    <div class="col-md-9">
      <section id="hme2">
        <div class="container">
          <div class="row">
            <div class="col-md-12 text-center">
              <h1 style="font-size: 23px; padding-bottom: 15px">
                A Competency Development and IT Training Organization with a
                global footprint
              </h1>
            </div>
          </div>
        </div>

        <div class="container">
          <div class="row hme_intro">
            <div class="col-md-4 col-sm-12 col-xs-12">
              <p><strong>40,000 +</strong><br />IT Professionals Trained</p>
            </div>
            <div class="col-md-4 col-sm-12 col-xs-12">
              <p><strong>10000 +</strong><br />Employed IT Freshers</p>
            </div>
            <div class="col-md-4 col-sm-12 col-xs-12">
              <p><strong>50 +</strong><br />Delighted Customers</p>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12 text-center">
              <p>
                GlobalEdify is a premium Competency Development and IT Training
                organization primarily focused on developing aspiring young IT
                talents around the world. SkillAssure, our innovative Competency
                Development Framework has won accolades from several Fortune 500
                companies, large IT services and product companies for enabling
                rapid deployment of the new hires into projects.
              </p>
            </div>
          </div>
        </div>
        <div class="clear"></div>
      </section>

      <section id="tab_pages" class="text-center">
        <div class="container">
          <div class="row hme_intro">
            <div class="col-md-4 col-sm-12 col-xs-12">
              <p>
                <a href=""><strong>Students / New Graduates</strong></a>
              </p>
              <ul class="text-left">
                <li>
                  Stand out and be sought after. Carve a career by choice and
                  not by chance.
                </li>
                <li>
                  Acquire all round workplace competencies critical for your
                  success.
                </li>
                <li>Achieve high performance early in your career.</li>
                <li>
                  Discover and nurture the entrepreneur in you. Harness the
                  innovative mindset to solve global problems through technology
                  adoption.
                </li>
                <li>
                  Benchmark yourself against the best of minds across the globe.
                </li>
              </ul>
            </div>
            <div class="col-md-4 col-sm-12 col-xs-12">
              <p>
                <a href=""><strong>Academic Institutions</strong></a>
              </p>
              <ul class="text-left">
                <li>
                  Become the preferred destination for IT companies seeking
                  premium talent.
                </li>
                <li>
                  Pioneer the change in bridging the gap between the academia
                  and the industry.
                </li>
                <li>
                  A Global Campus within your Campus - Create an eco-system of
                  Innovation and Excellence by leveraging the best practices
                  around the world.
                </li>
                <li>
                  Attract the best global talent as you move up the value chain.
                </li>
                <li>
                  Create the next generation entrepreneurs through Talent
                  Incubation on campus.
                </li>
              </ul>
            </div>
            <div class="col-md-4 col-sm-12 col-xs-12">
              <p>
                <a href=""><strong>Corporate / Startups</strong></a>
              </p>
              <ul class="text-left">
                <li>
                  Enable rapid deployment of your new college grads into
                  projects.
                </li>
                <li>Save huge costs in ‘time to deployment’ of a new grad.</li>
                <li>
                  Create a pipeline of young talent to give yourself the
                  competitive advantage.
                </li>
                <li>
                  Your virtual research and innovation team - access the best
                  minds on campus, across the globe, to create proof of concepts
                  and do product research.
                </li>
                <li>
                  Premium talent at your fingertips. Recruiting new grads was
                  never so easy.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section id="hme2">
        <div class="container">
          <div class="row">
            <div class="col-md-12 text-center">
              <h2>
                Go beyond academics and give yourself the edge over millions of
                young graduates and peers in the industry.
              </h2>
              <h3>
                Discover how we have SkillAssured several thousand IT engineers
                in the industry and enabled rapid deployment into projects.
              </h3>
              <p>
                <img
                  src="../../../assets/img/skill-assure-competency.jpg"
                  style="max-width: 970px"
                />
              </p>
            </div>
            <div class="col-md-8 col-sm-offset-2">
              <ul class="text-left skill_comp">
                <li>
                  An innovative Competency Development Framework that helps you
                  rapidly acquire skills and industrial competencies.
                </li>
                <li>
                  Take a hands on approach to learning as you interact with
                  mentors and practitioners from the industry.
                </li>
                <li>
                  You can churn out an unbelievable 25,000 lines of code in as
                  little as 3 to 4 months of time. Nothing makes you perfect as
                  doing the real thing.
                </li>
                <li>
                  Experience our unique learner centric model based on iterative
                  learning principles, agile methodologies and a culture of
                  innovation and teamwork. SkillAssure is a happy departure from
                  the conventional learning models.
                </li>
                <li>
                  Through a blend of modern techniques and ancient principles,
                  we have reinvented learning for the new age talent.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </div>

    <div class="col-md-3 side_bar_blk">
      <div class="container">
        <section id="slider_bottom">
          <div class="fader">
            <img
              src="../../../assets/img/gih-bot1.jpg"
              style="display: block"
            />
            <img src="../../../assets/img/campus-bot1.jpg" />
            <img src="../../../assets/img/gih-bot1.jpg" />
            <img src="../../../assets/img/campus-bot1.jpg" />
            <img src="../../../assets/img/gih-bot1.jpg" />
          </div>
        </section>
        <section id="case_study">
          <p>
            <a href=""><img src="../../../assets/img/case-study.jpg" /></a>
          </p>
        </section>
        <section id="news_events">
          <h3>News & Events</h3>
          <div class="" style="min-height: 400px">
            <marquee
              bgcolor="transparent"
              scrollamount="8"
              direction="up"
              loop="true"
              height="380px"
              onmouseover="this.stop()"
              onmouseout="this.start()"
            >
              <p>
                1. <strong>Mindtree</strong><br />Learn how 1000 people were
                rapidly enabled using project based learning model in under 8
                months!
              </p>

              <p>
                2. <strong>Quintiles</strong><br />50 premium talent deployed
                from 22,000 potential students – Learn how we delivered in under
                3 months!
              </p>

              <p>3. <strong>AHC LLC</strong><br />A silent revolution…</p>

              <p>
                4. <strong>East Point College</strong><br />Having fun while
                learning and landing up in a posh job
              </p>

              <p>5. <strong>Sinde</strong><br />Learn how a LMS was built</p>

              <p>
                6. <strong>AHC LLC</strong><br />Unearthing hidden GEMS<br />
                40 world class performers identified out of 80,000 students
              </p>

              <p>
                7. <strong>Siemens</strong><br />300 strong healthcare workforce
              </p>

              <p>8. <strong>CREDAXIS</strong><br />Culture of Innovation…</p>

              <p>
                9. <strong>Amadeus</strong><br />0 to 600 strong workforce?
                Learn how we did it.
              </p>
            </marquee>
          </div>
        </section>
      </div>
    </div>
  </div>

  <section id="clients">
    <div class="container">
      <div class="row">
        <div class="col-md-12 nopadding">
          <h1 style="text-align: center; margin-bottom: 15px">Our Clients</h1>
          <div class="client-slider" >
            <ul
              class="gallery js-flickity"
              data-flickity-options='{ "freeScroll": true, "wrapAround": true, "autoPlay": true, "autoPlay": 1000 }'
            >
              <li class="gallery-cell">
                <a href="clients"
                  ><img src="../../../assets/img/client/ab-innovative.jpg"
                /></a>
              </li>
              <li>
                <a href="clients"
                  ><img src="../../../assets/img/client/accenture.jpg"
                /></a>
              </li>
              <li>
                <a href="clients"
                  ><img src="../../../assets/img/client/all-script.jpg"
                /></a>
              </li>
              <li>
                <a href="clients"
                  ><img src="../../../assets/img/client/all-state.jpg"
                /></a>
              </li>
              <li>
                <a href="clients"
                  ><img src="../../../assets/img/client/amadeus.jpg"
                /></a>
              </li>
              <li>
                <a href="clients"
                  ><img src="../../../assets/img/client/antares.jpg"
                /></a>
              </li>
              <li>
                <a href="clients"
                  ><img src="../../../assets/img/client/areva.jpg"
                /></a>
              </li>
              <li>
                <a href="clients"
                  ><img src="../../../assets/img/client/cerner.jpg"
                /></a>
              </li>
              <li>
                <a href="clients"
                  ><img src="../../../assets/img/client/compassites.jpg"
                /></a>
              </li>
              <li>
                <a href="clients"
                  ><img src="../../../assets/img/client/endeavour.jpg"
                /></a>
              </li>
              <li>
                <a href="clients"
                  ><img src="../../../assets/img/client/fiserv.jpg"
                /></a>
              </li>
              <li>
                <a href="clients"
                  ><img src="../../../assets/img/client/flexera.jpg	 "
                /></a>
              </li>
              <li>
                <a href="clients"
                  ><img src="../../../assets/img/client/ge.jpg"
                /></a>
              </li>
              <li>
                <a href="clients"
                  ><img src="../../../assets/img/client/hcl.jpg"
                /></a>
              </li>
              <li>
                <a href="clients"
                  ><img src="../../../assets/img/client/infinite.jpg"
                /></a>
              </li>
              <li>
                <a href="clients"
                  ><img src="../../../assets/img/client/keystone-logic.jpg"
                /></a>
              </li>
              <li>
                <a href="clients"
                  ><img src="../../../assets/img/client/manhattan.jpg"
                /></a>
              </li>
              <li>
                <a href="clients"
                  ><img src="../../../assets/img/client/mindtree.jpg"
                /></a>
              </li>
              <li>
                <a href="clients"
                  ><img src="../../../assets/img/client/nds.jpg"
                /></a>
              </li>
              <li>
                <a href="clients"
                  ><img src="../../../assets/img/client/ness.jpg"
                /></a>
              </li>
              <li>
                <a href="clients"
                  ><img src="../../../assets/img/client/novell.jpg"
                /></a>
              </li>
              <li>
                <a href="clients"
                  ><img src="../../../assets/img/client/opteamix.jpg"
                /></a>
              </li>
              <li>
                <a href="clients"
                  ><img src="../../../assets/img/client/oracle.jpg"
                /></a>
              </li>
              <li>
                <a href="clients"
                  ><img src="../../../assets/img/client/philips.jpg"
                /></a>
              </li>
              <li>
                <a href="clients"
                  ><img src="../../../assets/img/client/quintiles.jpg"
                /></a>
              </li>
              <li>
                <a href="clients"
                  ><img src="../../../assets/img/client/siemens.jpg"
                /></a>
              </li>
              <li>
                <a href="clients"
                  ><img src="../../../assets/img/client/sinda.jpg"
                /></a>
              </li>
              <li>
                <a href="clients"
                  ><img src="../../../assets/img/client/slk.jpg"
                /></a>
              </li>
              <li>
                <a href="clients"
                  ><img src="../../../assets/img/client/spider.jpg"
                /></a>
              </li>
              <li>
                <a href="clients"
                  ><img src="../../../assets/img/client/tcs.jpg"
                /></a>
              </li>
              <li>
                <a href="clients"
                  ><img src="../../../assets/img/client/wipfli.jpg"
                /></a>
              </li>
              <li>
                <a href="clients"
                  ><img src="../../../assets/img/client/wipro.jpg"
                /></a>
              </li>
              <li>
                <a href="clients"
                  ><img src="../../../assets/img/client/xerox.jpg"
                /></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="campus campus_img text-center" id="campus">
    <div class="container">
      <div class="row">
        <h1>Our Offerings</h1>
        <div class="col-md-6 col-sm-12 col-xs-12">
          <h2>Global Innvovation Hub</h2>
          <p>
            Global Edify has embarked on a visionary endeavor ‘Global Innovation
            Hub’ to develop young talents from 16 different countries across the
            globe.
          </p>

          <p>
            Global Innovation Hub aims to create several thousand young thought
            leaders who can apply technology and build innovative solutions.
          </p>

          <p><a href="global-innovation-hub.html">Learn More >></a></p>
        </div>
        <div class="col-md-6 col-sm-12 col-xs-12">
          <h2>Campus 1000</h2>
          <p>
            Global Edify’s Campus 1000 is specifically designed for the Indian
            students who desire to pursue a career in IT.
          </p>

          <p>
            Powered by SkillAssure, a unique iterative training framework,
            Campus 1000 offers a platform for the best young minds in India.
          </p>

          <p><a href="global-innovation-hub.html">Learn More >></a></p>
        </div>
      </div>
    </div>
  </section>

  <section class="features-intro land_cont_bx">
    <div class="container">
      <div class="row">
        <div class="col-md-12 text-center">
          <h1>Contact</h1>
          <h2>GlobalEdify Corporate Headquarters</h2>
          <p>
            <img
              src="../../../assets/img/map-landing.png"
              style="max-width: 750px"
            />
          </p>
          <p>
            No.184 & 185, Tapaswiji Info Park,<br />
            EPIP Zone, Whitefield,<br />
            Bangalore, Karnataka, India, 560 066<br />
            <!-- Phone Number: +91 - 080 – 65699965 / 66 / 67 -->
          </p>
        </div>
      </div>
    </div>
  </section>

  <!-- footer -->
  <app-footer></app-footer>
  <!-- footer -->

  <div id="toTop">
    <a href="#home"><img src="../../../assets/img/gotop.png" /></a>
  </div>
</body>
