import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutUsComponent } from './Components/about-us/about-us.component';
import { Campus1000Component } from './Components/campus1000/campus1000.component';
import { ClientsComponent } from './Components/clients/clients.component';
import { ContactUsComponent } from './Components/contact-us/contact-us.component';
import { CustomerTestimonialsComponent } from './Components/customer-testimonials/customer-testimonials.component';
import { GlobalInnovationHubComponent } from './Components/global-innovation-hub/global-innovation-hub.component';
import { HomeComponent } from './Components/home/home.component';
import { SkillassureFrameworkComponent } from './Components/skillassure-framework/skillassure-framework.component';
import { StudentSpeakComponent } from './Components/student-speak/student-speak.component';

const routes: Routes = [
  { path: '', component: HomeComponent,pathMatch: 'full'},
  { path: 'about-us', component: AboutUsComponent},
  { path: 'global-innovation-hub', component: GlobalInnovationHubComponent},
  { path: 'campus1000', component: Campus1000Component},
  { path: 'skillassure-framework', component: SkillassureFrameworkComponent},
  { path: 'customer-testimonials', component: CustomerTestimonialsComponent},
  { path: 'student-speak', component: StudentSpeakComponent},
  { path: 'clients', component: ClientsComponent},
  { path: 'contact-us', component: ContactUsComponent},
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
