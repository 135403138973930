<body id="home">
  <header>
    <section id="header_blk" class="logo">
      <div class="container">
        <div class="row">
          <div class="col-md-10 col-sm-10 col-xs-10 text-left">
            <a href=""
              ><img src="../../../assets/img/logo.png" alt="Logo" style="width: auto"
            /></a>
          </div>
          <!-- nav -->
          <app-nav></app-nav>
          <!-- nav -->
        </div>
      </div>
    </section>
  </header>

  <section id="slider_blk_img">
    <p><img src="../../../assets/img/customer-feed-back.jpg" /></p>
  </section>

  <section id="hme2" style="padding-bottom: 0px">
    <div class="cust_wt">
      <div class="container">
        <div class="row">
          <div class="col-md-12 text-center">
            <h1>Customer Testimonials</h1>
          </div>
        </div>
        <div class="row">
          <div class="col-md-2">
            <p><img src="../../../assets/img/ness.jpg" style="max-width: 163px" /></p>
          </div>
          <div class="col-md-10">
            <p>
              “Are we hiring freshers? Get Pratian to train them!!”<br />“Hire
              for my team if we are going with Pratian for training!”<br />These
              are a few of many conversations which we get to hear while hiring
              freshers!<br />It was in 2009; Ness hired a big lot of freshers
              for the first time in its history. The whole concentration was on
              finding the perfect model to train them; Pratian’s ‘Skill Assure’
              was the best match. That’s it! <br />We had a fixed benchmark
              then! The management is now confident to hire the freshers knowing
              that the Skill Assure model of training can make each of them a
              dependable resource!<br />We thought the recent batch of freshers
              with B.Sc., BCA & MCAs could be a real challenge for us; but not
              indeed! The dedication from the Pratian team was so good that we
              could see the same in the freshers also!
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="cust_grey">
      <div class="container">
        <div class="row">
          <div class="col-md-10">
            <p>
              The individual attention is a key which ensured that each
              candidate understands the concepts well & can implement it
              practically. We can go on writing for pages together if we get
              into details! <br />“… it was a very nice experience and I am
              really glad that I got such an opportunity to be a part of this
              training”… <br />“Skill Assure Model, as its name suggests,
              assures to pour some real time development skills into the
              trainee.”<br />“...code monkeys are shaped as professional
              software engineers!!”<br />These expressions from few of the
              trainees say it all!!
            </p>
          </div>
          <div class="col-md-2">
            <p><img src="../../../assets/img/ness2.jpg" style="max-width: 163px" /></p>
          </div>
        </div>
      </div>
    </div>

    <div class="cust_wt">
      <div class="container">
        <div class="row">
          <div class="col-md-2">
            <p><img src="../../../assets/img/pilips.jpg" style="max-width: 163px" /></p>
          </div>
          <div class="col-md-10">
            <p>
              “Our association with Pratian is long standing and it has been an
              enriching journey together.
            </p>
            <p>
              While the trainers’ expertise has helped the participants gaining
              deeper subject matter understanding, their interest in helping us
              create robust Learning Programs has resulted in an improved
              quality of our programs”.
            </p>
            <p>
              <strong>Pragya Shrimali,</strong><br />Learning & Development<br />Philips
              Electronics India Ltd
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="cust_grey">
      <div class="container">
        <div class="row">
          <div class="col-md-10">
            <p>
              “Pratian team has conducted fresher training programs for us and
              while we have interacted regularly on feedback, I thought I should
              sum up our observations.<br />Over the course of the programs, we
              were impressed by your trainers who used a creative curriculum as
              a vehicle to impart sound programming skills and healthy
              professional habits.<br />We also appreciate your efforts to
              understand our needs and seek constructive feedback. The
              combination of technical proficiency and an unrelenting focus on
              higher-order learning outcomes is a strength you bring to the
              table. Thank you for your commitment to improving learning.”
            </p>
            <p>
              <strong>V. Krishnan</strong> Head (Campus Force - Training and
              Deployment) - HCL Technologies
            </p>
          </div>
          <div class="col-md-2">
            <p><img src="../../../assets/img/hcl.jpg" style="max-width: 163px" /></p>
          </div>
        </div>
      </div>
    </div>

    <div class="cust_wt">
      <div class="container">
        <div class="row">
          <div class="col-md-2">
            <p><img src="../../../assets/img/cb.jpg" style="max-width: 163px" /></p>
          </div>
          <div class="col-md-10">
            <p>
              "Hiring fresher’s through Pratian is one of the best hiring
              decisions we made at ChargeBee.<br />Getting experienced good
              developers for product start-ups is next to impossible in India.
              PRATIANS (as we call them) are well trained, ready to go on the
              job and have the right skills and attitude that we were looking
              for.<br />If you compare with cost of filtering the resumes, then
              interviewing and then hiring and then training someone it is a
              huge win for us ".
            </p>
            <p><strong>KP Saravanan</strong> - Co-Founder, ChargeBee</p>
          </div>
        </div>
      </div>
    </div>

    <div class="cust_grey">
      <div class="container">
        <div class="row">
          <div class="col-md-10">
            <p>
              I engaged with Pratian during my stint as the Head of Serus
              Corporation’s India development center. As a small company
              competing for competent engineering talent in Bangalore’s
              super-hot job market, Serus had an uphill task attracting highly
              talented risk-takers into the organization. Given that we were a
              small company with a great deal of autonomy and control in the
              India center, we were able to bring on board senior Architects and
              engineers with reasonable success.<br />However, it was most
              difficult to attract the younger engineers at the lower end of the
              experience scale. We turned to Pratian to support us in this
              process by injecting some fresh college grads into our workforce.
              With fierce delivery deadlines, none of our senior engineers had
              the bandwidth to train fresh grads – a gap that Pratian filled
              wonderfully.<br />Pratian first understood the requirements of
              Serus’ business and then put the student through a 3 month
              training program of high intensity and quality, customized to fit
              our business requirements. The program had checkpoints where Serus
              engineers had a chance to evaluate the students for their progress
              and suitability for the roles that were available – and fine tune
              the training mid-stream.<br />The partnership with Pratian solved
              not just our recruitment challenge, but also our training
              challenge – and as a result, we got very good engineers who could
              hit the ground running.
            </p>

            <p>Guru Bhat</p>
          </div>
          <div class="col-md-2">
            <p><img src="../../../assets/img/serus.jpg" style="max-width: 163px" /></p>
          </div>
        </div>
      </div>
    </div>

    <div class="cust_wt">
      <div class="container">
        <div class="row">
          <div class="col-md-2">
            <p><img src="../../../assets/img/amads.jpg" style="max-width: 163px" /></p>
          </div>
          <div class="col-md-10">
            <p>
              Amadeus Labs has been partnering with Pratian Technologies for the
              last 5 years now. Pratian manages our Campus Hire Training
              program. The SkillAssure program is ideally designed to make
              campus hires ready for the technical complexities needed for core
              product engineering. We are very happy with the results that we
              are seeing for the last few years. The engineers trained by
              Pratian have been exceeding all expectations of the delivery
              managers. We have also engaged Pratian for training programs for
              lateral hires. I thank Pratian Technologies for the passion they
              bring to their work and wish them all the best“
            </p>
            <p>
              Best Regards, <br />Mani Ganeshan - Associate Vice President,
              Amadeus Labs
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="cust_grey">
      <div class="container">
        <div class="row">
          <div class="col-md-10">
            <p>
              Most Fresher training programs view it as a laundry list of topics
              that can be 'somehow' completed in two months within a
              pre-determined budget, minimizing the per-person training cost,
              and hopefully teach some useful skills (getting a 4.5+ feedback
              rating from the kids is no big deal really). They key question is
              what did they learn, how relevant it was as a foundation and how
              effectively can they apply it in the first week of joining regular
              work - all, while being relatively independent and not leech on
              others time for guidance. In my experience this is the only
              program that delivers on those objectives. <br />It is designed
              for those organizations whose focus is to build a strong
              foundation of highly skilled engineers who can work seamlessly
              with an established team. The program focuses on foundational
              topics in technology that make an immediate impact on business,
              and covers auxiliary process skills as well to make every
              candidate well rounded on the day of completion. The basic premise
              behind the program is to go deeper than broader in creating that
              solid foundation - which makes scaling up relatively painless.
              <br />I would also recommend having an extended dialogue with
              Subbu in designing the program - his focus on meeting the end
              objective makes the discussion oriented towards tweaking for
              results rather than seem like mindless negotiation. His commitment
              and passion is a breath of fresh air in the otherwise crowded
              marketplace
            </p>
            <p>Anil Sarapalli - General Manager</p>
          </div>
          <div class="col-md-2">
            <p><img src="../../../assets/img/fiserv.jpg" style="max-width: 163px" /></p>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- footer -->
  <app-footer></app-footer>
  <!-- footer -->

  <div id="toTop">
    <a href="customer-testimonials#home"><img src="../../../assets/img/gotop.png" /></a>
  </div>
</body>
