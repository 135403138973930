<body id="home">
  <header>
    <section id="header_blk" class="logo">
      <div class="container">
        <div class="row">
          <div class="col-md-10 col-sm-10 col-xs-10 text-left">
            <a href=""
              ><img src="../../../assets/img/logo.png" alt="Logo" style="width: auto"
            /></a>
          </div>
          <!-- nav -->
          <app-nav></app-nav>
          <!-- nav -->
        </div>
      </div>
    </section>
  </header>

  <section id="slider_blk">
    <ul class="bxslider">
      <li><img src="../../../assets/img/slide1.jpg" /></li>
      <li><img src="../../../assets/img/slide2.jpg" /></li>
      <li><img src="../../../assets/img/slide3.jpg" /></li>
      <li><img src="../../../assets/img/slide4.jpg" /></li>
      <li><img src="../../../assets/img/slide5.jpg" /></li>
      <li><img src="../../../assets/img/slide6.jpg" /></li>
      <li><img src="../../../assets/img/slide7.jpg" /></li>
      <li><img src="../../../assets/img/slide8.jpg" /></li>
    </ul>
  </section>

  <section class="campus" id="campus">
    <div class="container">
      <div class="row">
        <div class="col-md-12 text-center">
          <h1>Campus 1000</h1>
          <p>
            Global Edify’s Campus 1000 is specifically designed for the Indian
            students who desire to pursue a career in IT.
          </p>
          <p>
            Students who get selected for Campus 1000 program will be absorbed
            in the Global Innovation Hub ecosystem where they will be a part of
            a global team.
          </p>
          <p>
            Powered by SkillAssure, a unique iterative training framework,
            Campus 1000 offers a platform for the best young minds in India.
          </p>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12 col-sm-12 text-center col-xs-12">
          <h2
            style="
              padding-bottom: 12px;
              border-bottom: 1px dashed #185c8b;
              width: 90%;
              margin: 5px auto;
              max-width: 230px;
            "
          >
            Program Details
          </h2>
          <p>Course Duration - 4 months</p>
          <h2>Key Highlights</h2>
          <p>
            Students who clear the assessment will avail 100% sponsorship from
            Global Edify partners and clients.
          </p>
        </div>
      </div>

      <div class="row">
        <div class="col-md-4 col-sm-12 col-xs-12 text-center">
          <p>Program Fee - INR 75,000</p>
        </div>
        <div class="col-md-4 col-sm-12 col-xs-12 text-center">
          <p>Registration Fee – INR 500</p>
          <p>
            <a
              href="https://www.pratian.com/pratianpayment/"
              class="btn btn-primary"
              target="_blank"
              style="background-color: #e58a25; border-color: #e58a25"
              ><strong>Launch Pad - Registration</strong></a
            >
          </p>
        </div>
        <div class="col-md-4 col-sm-12 col-xs-12 text-center">
          <p>Geography - India</p>
        </div>
      </div>
    </div>
  </section>

  <section class="campus campus_img text-center" id="campus">
    <div class="row">
      <h1>Campus 1000 Program Components</h1>
      <div class="col-md-6 col-sm-12 col-xs-12">
        <p style="margin-top: 25%"><img src="../../../assets/img/campus1.png" /></p>
      </div>
      <div class="col-md-6 col-sm-12 col-xs-12">
        <p><img src="../../../assets/img/campus2.png" style="max-width: 651px" /></p>
      </div>
    </div>
  </section>

  <section class="campus" id="campus">
    <div class="container">
      <div class="row">
        <div class="col-md-6 col-sm-12 col-xs-12">
          <h2>PHASE 1</h2>
          <h3>Registration</h3>
          <ul>
            <li>
              Register for Campus 1000 online or with the authorized education
              partner in your state
            </li>
            <li>
              Upload a 2 to 3 minutes of YouTube video introducing yourself /
              explaining why you wish to be a part of the program and share the
              link
            </li>
            <li>Signup for the free LaunchPad program</li>
          </ul>

          <h3>LaunchPad</h3>
          <ul>
            <li>
              Launchpad is a 20 hours self-paced learning program designed to
              help the learner acquire critical programing skills
            </li>
            <li>
              Prepare yourself for the assessment by taking the online mock
              tests
            </li>
            <li>Medium of instruction is Java</li>
          </ul>

          <h3>Program Elements</h3>
          <ul>
            <li>Programing Logic and Techniques</li>
            <li>SQL Programming</li>
          </ul>

          <h3>Talent Assessments</h3>
          <ul>
            <li>
              Register online for the assessment on the date and time specified
              for your country
            </li>
            <li>
              Online assessment will have two rounds where the students will be
              tested on their programing and SQL skills
            </li>
            <li>Round 1 will be based on multiple choice questions (MCQs)</li>
            <li>Round 2 will be a Code Jam</li>
            <li>
              Students who clear the assessment will be eligible for the next
              phase of the program
            </li>
          </ul>
        </div>
        <div class="col-md-6 col-sm-12 col-xs-12">
          <h2>PHASE 2</h2>
          <h3>Talent Incubation</h3>
          <ul>
            <li>
              Get introduced to the world of Enterprise Application Development
            </li>
            <li>Learn the essentials needed</li>
            <li>
              Get exposed to various components -Experience the environment
            </li>
          </ul>
          <h3>Skill Acquisition</h3>
          <ul>
            <li>
              Demystify Technology -Explore the Spectrum -What is new is not
              necessarily difficult!
            </li>
            <li>Get introduced to the world of Technology -Learn and Apply</li>
            <li>
              Get deep into technology learning. Hands-On: The primary approach
            </li>
            <li>
              The Iterative methodology and the Best Practices followed will
              ensure Skill Acquisition.
            </li>
          </ul>

          <h3>Competency Development</h3>
          <ul>
            <li>Learn about the advance technology elements & frameworks.</li>
            <li>
              Think like an expert by applying your Design and Technology
              Skills.
            </li>
            <li>Exciting end to end implementation of technology</li>
            <li>
              This phase enhances your technology adoption and thinking to
              implement solutions of a higher complexity and quality, focusing
              on competency development
            </li>
          </ul>

          <h3>Learning Iterations</h3>
          <ul>
            <li>
              Iteration 0 -Meet the Pre-Requisites (Address the Essentials)
            </li>
            <li>Iteration 1 -Demystify Technology (Explore the Spectrum)</li>
            <li>Iteration 2 -Deep Dive (Learn and Apply)</li>
            <li>Iteration 3 -Expert Thinking (Competency Development)</li>
          </ul>
        </div>
      </div>
    </div>
  </section>

  <section class="campus campus_img text-center" id="campus">
    <div class="row">
      <h1>SKILLASSURE</h1>
      <h2>Unique Iterative Framework</h2>
      <div class="col-md-6 col-sm-12 col-xs-12">
        <p><img src="../../../assets/img/campus-gih1.png" /></p>
      </div>
      <div class="col-md-6 col-sm-12 col-xs-12">
        <p><img src="../../../assets/img/campus-gih2.png" /></p>
      </div>
    </div>
  </section>

  <section class="campus campus_bg" id="campus">
    <div class="container">
      <div class="row">
        <div class="col-md-12 col-sm-12 text-center col-xs-12">
          <h2>PHASE 3</h2>
          <h2>Project on Campus</h2>
        </div>
      </div>

      <div class="row" style="margin-top: 20px">
        <div class="col-md-6 col-sm-12 col-xs-12">
          <h3>Ideate</h3>
          <p>Identify real world problems to solve</p>

          <h3>Collaborate</h3>
          <p>Work with peers across the world</p>

          <h3>Innovate</h3>
          <p>Apply technology in creative thinking</p>
        </div>
        <div class="col-md-6 col-sm-12 col-xs-12">
          <h3>Features and Benefits</h3>
          <ul>
            <li>
              Get trained from experts and get opportunity to learn about new
              technology
            </li>
            <li>
              Work in diverse teams and implement entire projects in an Agile
              manner
            </li>
            <li>Get exposed to different thinking process and skill sets</li>
            <li>
              Certification will be provided after successful completion of
              project
            </li>
            <li>
              Students will become an attractive proposition to prospective
              employers as they know the latest technology and are exposed to
              latest tools of the industry
            </li>
            <li>
              Students will become aware of competencies expected in an employee
              in the industry
            </li>
            <li>
              Students will get an opportunity to be a part of a Global
              Innovation Hub
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>

  <!-- footer -->
  <app-footer></app-footer>
  <!-- footer -->

  <div id="toTop">
    <a href="campus1000#home"><img src="../../../assets/img/gotop.png" /></a>
  </div>
</body>
