<body id="home">
  <header>
    <section id="header_blk" class="logo">
      <div class="container">
        <div class="row">
          <div class="col-md-10 col-sm-10 col-xs-10 text-left">
            <a href=""
              ><img src="../../../assets/img/logo.png" alt="Logo" style="width: auto"
            /></a>
          </div>
          <!-- nav -->
          <app-nav></app-nav>
          <!-- nav -->
        </div>
      </div>
    </section>
  </header>

  <section id="slider_blk">
    <p><img src="../../../assets/img/contact.jpg" /></p>
  </section>

  <footer>
    <div class="container">
      <div class="row">
        <div class="col-md-3">
          <h1>Contact Details</h1>
          <!-- <p>#186/2, Tapasviji Arcade,<br>Hosur main road, BTM 1<sup>st</sup> Stage,<br>Near Central Silk Board,<br>Bangalore-560076. KARNATAKA<br>Phone: +91 - 080 – 65699965 / 66 / 67</p> -->
          <p>
            No.184 & 185, Tapaswiji Info Park,<br />EPIP Zone, Whitefield,<br />
            Bangalore, Karnataka, India, 560 066<br />
            <!-- Phone: +91 - 080 – 65699965 / 66 / 67 -->
          </p>
        </div>
        <div class="col-md-6">
          <p><img src="../../../assets/img/global.jpg" style="max-width: 536px" /></p>
        </div>
        <div class="col-md-3">
          <p><img src="../../../assets/img/india.jpg" style="max-width: 235px" /></p>
        </div>
      </div>
    </div>
  </footer>

  <!-- footer -->
  <app-footer></app-footer>
  <!-- footer -->

  <div id="toTop">
    <a href="contact-us#home"><img src="../../../assets/img/gotop.png" /></a>
  </div>
</body>
