<div class="col-md-2 col-sm-2 col-xs-2 text-right navicon">
    <p>MENU</p>
    <a id="" class="nav-toggle" (click)="clickshow()"><span></span></a>
    <div class="overlay" (click)="clickshow()" *ngIf=display>
        <nav>
            <ul>
                <li><a href="/about-us">About Us</a></li>
                <li><a href="/global-innovation-hub">Global Innovation Hub</a></li>
                <li><a href="/campus1000">Campus 1000</a></li>
                <li><a href="/skillassure-framework">SkillAssure Framework</a></li>
                <li><a href="/customer-testimonials">Customer Testimonial</a></li>
                <li><a href="/student-speak">Student Speak</a></li>
                <li><a href="/clients">Clients</a></li>
                <li><a href="/contact-us">Contact Us</a></li>
            </ul>
        </nav>
    </div>
</div>