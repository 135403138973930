import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './Components/home/home.component';
import { AboutUsComponent } from './Components/about-us/about-us.component';
import { FooterComponent } from 'src/assets/shared/footer/footer.component';
import { NavComponent } from 'src/assets/shared/nav/nav.component';
import { GlobalInnovationHubComponent } from './Components/global-innovation-hub/global-innovation-hub.component';
import { Campus1000Component } from './Components/campus1000/campus1000.component';
import { SkillassureFrameworkComponent } from './Components/skillassure-framework/skillassure-framework.component';
import { CustomerTestimonialsComponent } from './Components/customer-testimonials/customer-testimonials.component';
import { StudentSpeakComponent } from './Components/student-speak/student-speak.component';
import { ClientsComponent } from './Components/clients/clients.component';
import { ContactUsComponent } from './Components/contact-us/contact-us.component';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AboutUsComponent,
    FooterComponent,
    NavComponent,
    GlobalInnovationHubComponent,
    Campus1000Component,
    SkillassureFrameworkComponent,
    CustomerTestimonialsComponent,
    StudentSpeakComponent,
    ClientsComponent,
    ContactUsComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent],

})
export class AppModule { }
