<body id="home">
  <header>
    <section id="header_blk" class="logo">
      <div class="container">
        <div class="row">
          <div class="col-md-10 col-sm-10 col-xs-10 text-left">
            <a href=""
              ><img
                src="../../../assets/img/logo.png"
                alt="Logo"
                style="width: auto"
            /></a>
          </div>
          <!-- nav -->
          <app-nav></app-nav>
          <!-- nav -->
        </div>
      </div>
    </section>
  </header>

  <section id="slider_blk_img">
    <p><img src="../../../assets/img/skill-assure.jpg" /></p>
  </section>

  <section id="hme2" style="padding-bottom: 20px">
    <div class="container">
      <div class="row">
        <div class="col-md-12 text-center">
          <h1>SkillAssure Framework</h1>

          <p>
            It is a Specialist Skills based Competency & Talent Development
            Framework that is designed on the principles of Iterative Training
            Methodology
          </p>

          <p>
            A proven program that has over the years churned about 11,000
            graduates to be Ready-To-Deploy (RTD) professionals
          </p>

          <p>
            It helps to transform young students into a real-world class
            workforce to provide a highly scalability, robustness and truly
            reliability solutions for the global market
          </p>
        </div>
      </div>
    </div>
  </section>

  <section class="campus campus_img text-center" id="campus">
    <div class="row">
      <h1>SKILLASSURE</h1>
      <h2>Unique Iterative Framework</h2>
      <div class="col-md-6 col-sm-12 col-xs-12">
        <p><img src="../../../assets/img/campus-gih1.png" /></p>
      </div>
      <div class="col-md-6 col-sm-12 col-xs-12">
        <p><img src="../../../assets/img/campus-gih2.png" /></p>
      </div>
    </div>
  </section>

  <!-- footer -->
  <app-footer></app-footer>
  <!-- footer -->

  <div id="toTop">
    <a href="skillassure-framework#home"><img src="../../../assets/img/gotop.png" /></a>
  </div>
</body>
