<body id="home">
  <header>
    <section id="header_blk" class="logo">
      <div class="container">
        <div class="row">
          <div class="col-md-10 col-sm-10 col-xs-10 text-left">
            <a routerLink="/home"
              ><img
                src="../../../assets/img/logo.png"
                alt="Logo"
                style="width: auto"
            /></a>
          </div>
          <!-- nav -->
          <app-nav></app-nav>
          <!-- nav -->
        </div>
      </div>
    </section>
  </header>

  <section id="slider_blk">
    <ul class="bxslider">
      <li><img src="../../../assets/img/slide1.jpg" /></li>
      <li><img src="../../../assets/img/slide2.jpg" /></li>
      <li><img src="../../../assets/img/slide3.jpg" /></li>
      <li><img src="../../../assets/img/slide4.jpg" /></li>
      <li><img src="../../../assets/img/slide5.jpg" /></li>
      <li><img src="../../../assets/img/slide6.jpg" /></li>
      <li><img src="../../../assets/img/slide7.jpg" /></li>
      <li><img src="../../../assets/img/slide8.jpg" /></li>
    </ul>
  </section>

  <section class="campus" id="campus">
    <div class="container">
      <div class="row">
        <div class="col-md-12 text-center">
          <h1>Global Innvovation Hub</h1>
          <p>
            Global Edify has embarked on a visionary endeavor ‘Global Innovation
            Hub’ to develop young talents from 16 different countries across the
            globe.
          </p>
          <p>
            Global Innovation Hub aims to create several thousand young thought
            leaders who can apply technology and build innovative solutions to
            global problems by understanding the macro perspective and by
            skillfully collaborating with people.
          </p>
          <p>
            Upto 150 students from 16 countries will collaborate to build
            innovative solutions after undergoing an advanced rapid skill
            acquisition program in niche technologies.
          </p>
          <p>
            Powered by SkillAssure, a unique iterative training framework,
            Global Innovation Hub offers a platform for the best young minds in
            the world to collaborate and build best in class solutions while
            still in campus.
          </p>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12 col-sm-12 text-center col-xs-12">
          <h2
            style="
              padding-bottom: 12px;
              border-bottom: 1px dashed #185c8b;
              width: 90%;
              margin: 5px auto;
              max-width: 230px;
            "
          >
            Program Details
          </h2>
          <p>Course Duration - 4 months</p>
          <h2>Key Highlights</h2>
          <p>
            Students who clear the assessment will avail 100% sponsorship from
            Global Edify partners and clients.
          </p>
        </div>
      </div>

      <div class="row">
        <div class="col-md-4 col-sm-12 col-xs-12 text-center">
          <h2>Program Fee</h2>
          <p>For Indian Students - INR 1,25,000</p>
          <p>For Students Outside India - USD 6000</p>
        </div>
        <div class="col-md-4 col-sm-12 col-xs-12 text-center">
          <h2>Registration Fee</h2>
          <p>For Indian Students - INR 1,000</p>
          <p>For Students Outside India - USD 100</p>
        </div>
        <div class="col-md-4 col-sm-12 col-xs-12 text-center">
          <h2>Geography</h2>
          <p>
            India, USA, Mexico, Columbia, China, Singapore, Indonesia, Israel,
            South Africa, UK, Australia, New Zealand, Japan, Oman, Saudi Arabia
            and Qatar
          </p>
        </div>
      </div>
    </div>
  </section>

  <section class="campus campus_img text-center" id="campus">
    <div class="row">
      <h1>GIH Program Components</h1>
      <div class="col-md-6 col-sm-12 col-xs-12">
        <p><img src="../../../assets/img/gih1.png" /></p>
      </div>
      <div class="col-md-6 col-sm-12 col-xs-12">
        <p><img src="../../../assets/img/gih2.png" /></p>
      </div>
    </div>
  </section>

  <section class="campus" id="campus">
    <div class="container">
      <div class="row">
        <div class="col-md-6 col-sm-12 col-xs-12">
          <h2>PHASE 1</h2>
          <h3>Registration</h3>
          <ul>
            <li>
              Register for Global Innovation Hub online or with the authorized
              education partner in your state
            </li>
            <li>
              Upload a 2 to 3 minutes of YouTube video introducing yourself /
              explaining why you wish to be a part of the program and share the
              link
            </li>
            <li>Signup for the free LaunchPad program</li>
          </ul>

          <h3>LaunchPad</h3>
          <ul>
            <li>
              Launchpad is a 20 hours self-paced learning program designed to
              help the learner acquire critical programing skills
            </li>
            <li>
              Prepare yourself for the assessment by taking the online mock
              tests
            </li>
            <li>Medium of instruction is Java</li>
          </ul>

          <h3>Program Elements</h3>
          <ul>
            <li>Programing Logic and Techniques</li>
            <li>SQL Programming</li>
          </ul>

          <h3>Talent Assessments</h3>
          <ul>
            <li>
              Register online for the assessment on the date and time specified
              for your country
            </li>
            <li>
              Online assessment will have two rounds where the students will be
              tested on their programing and SQL skills
            </li>
            <li>Round 1 will be based on multiple choice questions (MCQs)</li>
            <li>Round 2 will be a Code Jam</li>
            <li>
              Students who clear the assessment will be eligible for the next
              phase of the program
            </li>
          </ul>
        </div>
        <div class="col-md-6 col-sm-12 col-xs-12">
          <h2>PHASE 2</h2>
          <h3>Talent Incubation</h3>
          <ul>
            <li>
              Get introduced to the world of Enterprise Application Development
            </li>
            <li>Learn the essentials needed</li>
            <li>
              Get exposed to various components -Experience the environment
            </li>
          </ul>
          <h3>Skill Acquisition</h3>
          <ul>
            <li>
              Demystify Technology -Explore the Spectrum -What is new is not
              necessarily difficult!
            </li>
            <li>Get introduced to the world of Technology -Learn and Apply</li>
            <li>
              Get deep into technology learning. Hands-On: The primary approach
            </li>
            <li>
              The Iterative methodology and the Best Practices followed will
              ensure Skill Acquisition.
            </li>
          </ul>

          <h3>Competency Development</h3>
          <ul>
            <li>Learn about the advance technology elements & frameworks.</li>
            <li>
              Think like an expert by applying your Design and Technology
              Skills.
            </li>
            <li>Exciting end to end implementation of technology</li>
            <li>
              This phase enhances your technology adoption and thinking to
              implement solutions of a higher complexity and quality, focusing
              on competency development
            </li>
          </ul>

          <h3>Learning Iterations</h3>
          <ul>
            <li>
              Iteration 0 -Meet the Pre-Requisites (Address the Essentials)
            </li>
            <li>Iteration 1 -Demystify Technology (Explore the Spectrum)</li>
            <li>Iteration 2 -Deep Dive (Learn and Apply)</li>
            <li>Iteration 3 -Expert Thinking (Competency Development)</li>
          </ul>
        </div>
      </div>
    </div>
  </section>

  <section class="campus campus_img text-center" id="campus">
    <div class="row">
      <h1>SKILLASSURE</h1>
      <h2>Unique Iterative Framework</h2>
      <div class="col-md-6 col-sm-12 col-xs-12">
        <p><img src="../../../assets/img/campus-gih1.png" /></p>
      </div>
      <div class="col-md-6 col-sm-12 col-xs-12">
        <p><img src="../../../assets/img/campus-gih2.png" /></p>
      </div>
    </div>
  </section>

  <section class="campus campus_bg" id="campus">
    <div class="container">
      <div class="row">
        <div class="col-md-12 col-sm-12 text-center col-xs-12">
          <h2>Enterprise Development</h2>
          <p>
            Tie it all Together. Get your Technology, Process, and Environment
            Skills together for a real world requirement
          </p>
          <p>Wear the hat of "Design Thinking"</p>
          <p>
            Get onto the Cloud Infrastructure and access the artifacts from
            across the world
          </p>
          <p>
            Work with people across the globe. Team up with people in various
            countries to exchange information and work towards delivery
          </p>
          <p>Get exposed to the Industry -This is Exciting!</p>
        </div>
      </div>
    </div>
  </section>

  <!-- footer -->
  <app-footer></app-footer>
  <!-- footer -->

  <div id="toTop">
    <a href="global-innovation-hub#home"><img src="../../../assets/img/gotop.png" /></a>
  </div>
</body>
