<body id="home">
  <header>
    <section id="header_blk" class="logo">
      <div class="container">
        <div class="row">
          <div class="col-md-10 col-sm-10 col-xs-10 text-left">
            <a href=""
              ><img src="../../../assets/img/logo.png" alt="Logo" style="width: auto"
            /></a>
          </div>
          <!-- nav -->
          <app-nav></app-nav>
          <!-- nav -->
        </div>
      </div>
    </section>
  </header>

  <section id="slider_blk">
    <ul class="bxslider">
      <li><img src="../../../assets/img/slide1.jpg" /></li>
      <li><img src="../../../assets/img/slide2.jpg" /></li>
      <li><img src="../../../assets/img/slide3.jpg" /></li>
      <li><img src="../../../assets/img/slide4.jpg" /></li>
      <li><img src="../../../assets/img/slide5.jpg" /></li>
      <li><img src="../../../assets/img/slide6.jpg" /></li>
      <li><img src="../../../assets/img/slide7.jpg" /></li>
      <li><img src="../../../assets/img/slide8.jpg" /></li>
    </ul>
  </section>

  <section class="video" id="about">
    <div class="container">
      <div class="row">
        <div class="col-md-12 text-center col-md-12">
          <h1>About Us</h1>
          <p>Global Edify is the L&D outfit of Pratian Technologies.</p>
          <p>
            Its flagship programs powered by SkillAssure Framework has
            positively impacted thousands of young learners and aided their
            seamless transition from learners to most sought after IT
            professionals.
          </p>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6 col-md-12">
          <h2>Our Vision</h2>
          <p>To transform people’s life by reinventing education</p>

          <h2>
            Our Goals -
            <span style="color: rgb(229, 138, 37); font-size: 16px"
              >Create Thought Leaders</span
            >
          </h2>
          <p>
            We create learning models, frameworks and systems that allow rapid
            enablement of people, nurture research & innovation skills,
            encourage entrepreneurship and augment leadership qualities.
          </p>

          <p>
            We aspire to create several thousand young leaders who can offer
            holistic solutions to global problems by understanding the macro
            perspective and by skillfully collaborating with people across the
            boundaries, with a ‘heart at peace’
          </p>
        </div>

        <div class="col-md-6 col-md-12">
          <h2>Our Mantra</h2>
          <p>Manifesting the Talent Within</p>

          <h2>Our Mission</h2>
          <p>
            Create Innovative Experiential Learning Systems that are learner
            centric, designed to make learning fun and engaging.
          </p>

          <p>
            Create Smart Assessment Systems, discover talent and nurture them
            while they embark on their personalized learning paths.
          </p>

          <p>
            Create an Open Knowledge Sharing and Collaboration System that
            allows young talents across geographies to exchange ideas and
            innovate.
          </p>
        </div>
      </div>
    </div>
  </section>

  <section class="showcase">
    <div class="showcase-wrap">
      <div class="texture-overlay"></div>
      <div class="container">
        <div class="row">
          <div class="col-md-4">
            <p style="tet-align: center">
              <img src="../../../assets/img/screen1.png" alt="Image" style="max-width: 335px" />
            </p>
          </div>

          <div class="col-md-8">
            <h1>Our Values</h1>
            <h2>INTEGRITY</h2>
            <ul>
              <li>
                Honest and Authentic – across all transactions & relationships
              </li>
              <li>
                Honour Commitments – on Behalf of Self, Team & Organisation
              </li>
              <li>Walk The Talk</li>
            </ul>

            <h2>PASSION</h2>
            <ul>
              <li>Devotion to Mission, Vision and Values</li>
              <li>Pursue results and goals even under adverse circumstances</li>
              <li>Open to continuous learning</li>
            </ul>

            <h2>INNOVATION</h2>
            <ul>
              <li>
                Reframe the ‘problem’, as “a problem to solve” using alternative
                approaches
              </li>
              <li>
                Challenge the status-quo and the conventional approach using
                ‘out of the box’ mindset
              </li>
              <li>Continuously try something new, other than the obvious</li>
            </ul>

            <h2>EXCELLENCE</h2>
            <ul>
              <li>Expand the boundaries, with no fear of failure</li>
              <li>Raise the bar every time through continuous improvement</li>
              <li>Deliver your best each time and every time</li>
            </ul>

            <h2>COLLABORATION & TEAMWORK</h2>
            <ul>
              <li>
                Be proactively alive and responsive - to the needs of the
                organisation, people and its customers
              </li>
              <li>Work to build team commitment and spirit</li>
              <li>
                Solicit inputs without prejudice and support team decisions
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- footer -->
  <app-footer></app-footer>
  <!-- footer -->

  <div id="toTop">
    <a href="about-us#home"><img src="../../../assets/img/gotop.png" /></a>
  </div>
</body>
